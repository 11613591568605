import { useContext, useEffect, useState } from "react"
import LocationsTable from "../../components/tables/LocationsTable"
import UsersTable from "../../components/tables/UsersTable"
import useUser from "../../hooks/useUser"
import DataContext from "../../context/dataProvider"
import { sortByAttribute } from "../../utils/helper"
import { userService } from "../../services/userService"
import { TableOrder } from "../../Interfaces"
import { MANAGER } from "../../utils/roles"
import OrganizationsTable from "../../components/tables/OrganizationsTable"
import IncidentsTable from '../../components/tables/IncidentsTable'

export default function Dashboard() {
  const { id } = useUser()
  const { currentUser, setCurrentUser } = useContext(DataContext)
  const [tableOrder, setTableOrder] = useState<any[]>([
    { element: <IncidentsTable />, tableId: 4, displayOrder: 1, tableName: "IncidentsTable" },
    { element: <LocationsTable />, tableId: 1, displayOrder: 2, tableName: "LocationsTable" },
    { element: <UsersTable userProfileId={id} />, tableId: 2, displayOrder: 3, tableName: "UsersTable" },
    { element: currentUser.roleId && currentUser.roleId > MANAGER ? <OrganizationsTable /> : null, tableId: 3, displayOrder: 4, tableName: "OrganizationTable" },
  ])

  let movingTableIndex = 0
  let dropOverIndex = 0

  useEffect(() => {
    const newOrder = currentUser?.tableOrderPreference?.map((dbTable: TableOrder, i: any) => {
      const table = tableOrder.find((table) => table.tableId === dbTable.tableId)
      if (table) {
        table.displayOrder = dbTable.displayOrder
        table.id = dbTable.id
        return table
      }
      return null
    })
    if (newOrder?.length) {
      sortByAttribute(newOrder, "displayOrder")
      setTableOrder(() => newOrder)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  function handleDrag(e: any) {
    e.preventDefault()
    movingTableIndex = e.currentTarget.id[0]
    e.target.classList.add("invisible")
  }

  function handleDragOver(e: any) {
    e.preventDefault()
    dropOverIndex = e.currentTarget.id[0]
  }

  function handleDragEnd(e: any) {
    e.preventDefault()
    e.target.classList.remove("invisible")
    if (movingTableIndex === dropOverIndex) return
    const newOrder = tableOrder
    const movingTable = tableOrder[movingTableIndex]
    const dropOverTable = tableOrder[dropOverIndex]
    newOrder[movingTableIndex] = dropOverTable
    newOrder[dropOverIndex] = movingTable
    setTableOrder(() => [...newOrder])
    const filteredNewOrder = newOrder.filter((table) => (table ? true : false))
    const savedTableOrder = filteredNewOrder.map((table, i) => {
      return { tableId: table.tableId, tableName: table.tableName, displayOrder: i, id: table.id }
    })

    setCurrentUser((prev) => {
      return {
        ...prev,
        tableOrderPreference: savedTableOrder,
      }
    })
    // save to db
    savedTableOrder.forEach((table) => {
      if (table.id) {
        userService.updateTableOrder(table.tableId, table.tableName, table.displayOrder, table.id)
      } else {
        userService.updateTableOrder(table.tableId, table.tableName, table.displayOrder)
      }
    })
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-full h-fit">
        {tableOrder.map((table, i) => {
         
          return (
            
            <div className="transition ease-in duration-1000 " draggable onDrag={handleDrag} onDrop={() => "dropped"} onDragOver={handleDragOver} onDragEnd={handleDragEnd} key={i + "table"} id={i + "table"}>
              {table?.element ? table.element : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}
