import { Link, useLocation } from "react-router-dom"
import { Location, MarkerLocation, Walk } from "../../../../Interfaces"
import Map from "../../../../components/maps/Map"
import { useEffect, useRef, useState } from "react"
import { locationService } from "../../../../services/locationService"
import SaveButtonWithStatus from "../../../../components/general/SaveButtonWithStatus"
import { FaRedo, FaSave, FaUndo } from "react-icons/fa"
import ConfirmationModal from "../../../../components/modals/ConfirmationModal"
import NewWalkModal from "../../../../components/modals/NewWalkModal"
import { walkService } from "../../../../services/walkService"
import PopupModal from "../../../../components/modals/PopupModal"
import LocationUsersTable from "../../../../components/tables/LocationUsersTable"

let trashedMarkers: MarkerLocation[] = []

export default function CreatWalks() {
  const location = useLocation()
  const { currentLocation } = location.state as { currentLocation: Location }
  const [zoom, setZoom] = useState<number>(currentLocation.zoomLevel ? currentLocation.zoomLevel : 18)
  const [savingStatus, setSavingStatus] = useState<null | string>(null)
  const [savingWalkStatus, setSavingWalkStatus] = useState<null | string>(null)
  const [deleteStatus, setDeleteStatus] = useState<null | string>(null)
  const [markers, setMarkers] = useState<MarkerLocation[]>([])
  const [walks, setWalks] = useState<Walk[]>([])
  const [currentWalk, setCurrentWalk] = useState<null | Walk>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false)
  const [showNewWalkModal, setShowNewWalkModal] = useState<boolean>(false)
  const [walkName, setWalkName] = useState<string>("")
  const [walkDescription, setWalkDescription] = useState<string>("")
  const [showPopupModal, setShowPopupModal] = useState<boolean>(false)
  const coordinates = useRef<google.maps.LatLngLiteral>({ lat: currentLocation.latitude, lng: currentLocation.longitude })

  useEffect(() => {
    fetchWalks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchWalks = async () => {
    const fetchedWalks = await walkService.getAll(currentLocation.id || null)
    const filteredWalks = fetchedWalks?.filter((walk: Walk) => walk.deletedAt === null)
    setWalks(filteredWalks || [])
  }

  const handleSaveZoom = async () => {
    setSavingStatus("Saving...")
    try {
      const status = await locationService.update({ ...currentLocation, zoomLevel: zoom })
      if (status) {
        setSavingStatus("Saved!")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      } else {
        setSavingStatus("Error")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setSavingStatus("Error")
      setTimeout(() => {
        setSavingStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleSaveWalk = async () => {
    trashedMarkers = []
    // If user is editing a walk, create a new copy w/ new markers and delete (deleteAt flag) old walk
    if (currentWalk) {
      const newMarkers = markers.map((marker) => {
        delete marker.id
        return { ...marker, walkId: currentWalk.id }
      })
      if (!currentLocation.id) return
      const walkInfo = { name: currentWalk.name, description: currentWalk.description, locationId: currentLocation.id, markers: newMarkers }
      setSavingWalkStatus("Saving...")
      
      let status: Walk | null = null
      try {  
        status = await walkService.create(walkInfo)
        await walkService.delete(currentWalk.id)
        if (status) {
          setSavingWalkStatus("Saved!") // triggers data fetch
          setTimeout(async () => {
            fetchWalks()
            setSavingWalkStatus(null)
            setShowConfirmationModal(false)
            setMarkers([])
          }, 2000)
        }
      }
      catch (error) {
        // show error button or show error fallback page?
        setSavingWalkStatus("Error")
        setTimeout(() => {
          setSavingWalkStatus(null)
        }, 1500)
        // showBoundary(error)
      }
      // For new walks, create new walk
    } else {
      if (!currentLocation.id || !walkName) return
      const walkInfo = { name: walkName, description: walkDescription, locationId: currentLocation.id, markers: markers }
      setSavingWalkStatus("Saving...")
      let status: Walk | null = null
      try {
        status = await walkService.create(walkInfo)
        if (status) {
          setSavingWalkStatus("Saved!") // triggers data fetch
          setTimeout(async () => {
            fetchWalks()
            setSavingWalkStatus(null)
            setShowNewWalkModal(false)
            setMarkers([])
          }, 2000)
        }
      } catch (error) {
        // show error button or show error fallback page?
        setSavingWalkStatus("Error")
        setTimeout(() => {
          setSavingWalkStatus(null)
        }, 1500)
        // showBoundary(error)
      }
    }
  }

  const handleUndo = () => {
    if (!markers.length) return
    const trashedMarker = markers.pop()
    if (!trashedMarker) return
    trashedMarkers.push(trashedMarker)
    setMarkers((current) => [...current])
  }

  const handleRedo = () => {
    if (!trashedMarkers.length) return
    const restoredMarker = trashedMarkers.pop()
    markers.push(restoredMarker!!)
    setMarkers((current) => [...current])
  }

  const handleLoadWalk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    trashedMarkers = []
    if (e.currentTarget.id === "new walk") {
      setCurrentWalk(null)
      setMarkers([])
      return
    }
    const walkId = Number(e.currentTarget.id)
    const walk = walks.find((walk) => walk.id === walkId)
    if (!walk) return
    setCurrentWalk(walk)
    const sortedMarkers = walk.markers?.sort((a, b) => a.label - b.label)
    setMarkers(sortedMarkers || [])
  }

  const handleMarkers = (markers: MarkerLocation[]) => {
    trashedMarkers = []
    setMarkers((prev) => [...prev, ...markers])
  }

  const handleModal = (e: { currentTarget: { name: string } }) => {
    if (currentWalk) {
      if (e.currentTarget.name === "delete") {
        setShowDeleteConfirmationModal(true)
        return
      }
      setShowConfirmationModal(true)
    } else {
      setWalkName("")
      setWalkDescription("")
      setShowNewWalkModal(true)
    }
  }

  const handleDeleteWalk = async () => {
    if (!currentWalk) return
    setDeleteStatus("Deleting...")
    try {
      const status = await walkService.delete(currentWalk.id)
      if (status) {
        const updatedWalks = walks.filter((walk) => walk.id !== currentWalk.id)
        setWalks(updatedWalks)
        setDeleteStatus("Deleted!")
        setTimeout(() => {
          setDeleteStatus(null)
          setShowDeleteConfirmationModal(false)
          setCurrentWalk(null)
          setMarkers([])
        }, 2000)
      } else {
        setDeleteStatus("Error")
        setTimeout(() => {
          setDeleteStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setDeleteStatus("Error")
      setTimeout(() => {
        setDeleteStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleWalkName = (e: { target: { value: string } }) => {
    setWalkName(e.target.value)
  }

  const handleWalkDescription = (e: { target: { value: string } }) => {
    setWalkDescription(e.target.value)
  }

  const handleLocationAdjustment = (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
    coordinates.current.lat = Number(latLng.lat)
    coordinates.current.lng = Number(latLng.lng)
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <div className="w-full flex flex-col">
        <section className="bg-secondary-extra-light p-2 md:rounded-lg mb-2 shadow-lg h-full">
          <div className="bg-primary-dark h-full flex flex-col">
            <div className=" text-white rounded-lg p-4 flex justify-between">
              <div>
                <h3 className="">{currentLocation.name}</h3>
                <h4 className="">{currentLocation.address}</h4>
                <h4 className="">{`${currentLocation.city}, ${currentLocation.state} ${currentLocation.zipcode}`}</h4>
              </div>
              <div>
                <div className="flex justify-end p-2">
                  <button className="rounded-lg p-2 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" onClick={() => setShowPopupModal(true)}>
                    Employees
                  </button>
                  <Link to={`../locations/${currentLocation.id}`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light">
                    Walk Reports
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex flex-grow">
              <div className="shadow-lg  bg-secondary-extra-light w-full flex-grow relative">
                <Map zoom={zoom} setZoom={setZoom} name={currentLocation.name} coordinates={coordinates.current} handleLocationAdjustment={handleLocationAdjustment} setMarkers={handleMarkers} markers={markers} />
                <SaveButtonWithStatus saveStatus={savingStatus} handleSave={handleSaveZoom} text="Save Zoom Level" className="absolute w-40 bottom-1 left-1 shadow-right-lg" />
              </div>
              <div className="bg-secondary-dark w-52 flex flex-col items-center">
                <div className="flex w-full justify-between">
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center" aria-details="undo button" onClick={handleUndo}>
                    <FaUndo />
                  </button>
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center disabled:bg-slate-400" aria-details="save button" onClick={handleModal} disabled={!markers.length}>
                    <FaSave />
                  </button>
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center" aria-details="redo button" onClick={handleRedo}>
                    <FaRedo />
                  </button>
                </div>

                <h3 className="text-white">Storage Walks</h3>
                <hr className=" outline-none border-none h-px bg-secondary w-full my-2"></hr>
                {walks.map((walk) => {
                  return (
                    <button className={`p-2 w-full flex justify-center border-secondary hover:border-secondary-light ${currentWalk?.id === walk.id && "bg-secondary "}`} key={walk.id} onClick={handleLoadWalk} id={walk.id.toString()}>
                      <div className="flex w-3/4 items-center">
                        <p className="text-white">{walk.name}</p>
                      </div>
                    </button>
                  )
                })}
                <button className={`p-2 flex justify-center border-secondary hover:border-secondary-light w-full ${!currentWalk && "bg-secondary"}`} onClick={handleLoadWalk} id="new walk">
                  <div className="flex w-3/4">
                    <p className="text-white">New Walk</p>
                  </div>
                </button>
                <div className="w-full h-full flex items-end justify-center pb-2">
                  <div className="w-full flex flex-col items-center">
                    <button className="m-2 w-4/5 rounded-lg p-2 text-white text-center  border-2 border-secondary border-sedondary hover:border-secondary-light hover:bg-secondary shadow-lg disabled:invisible" aria-details="save button" onClick={handleModal} disabled={!markers.length}>
                      Save Walk
                    </button>
                    <button className="m-2 w-4/5 rounded-lg p-2 text-white text-center  border-2 border-secondary border-sedondary hover:border-secondary-light hover:bg-secondary shadow-lg disabled:hidden" name="delete" aria-details="save button" onClick={handleModal} disabled={!currentWalk}>
                      Delete Walk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showPopupModal && (
        <PopupModal isOpen={showPopupModal} onCancel={() => setShowPopupModal(false)}>
          <div className="w-full flex flex-wrap sm:rounded-lg m-4 p-4">
            <LocationUsersTable locationId={currentLocation.id || 0} />
          </div>
        </PopupModal>
      )}
      <ConfirmationModal isOpen={showConfirmationModal} title={`Overwrite ${currentWalk?.name}?`} message={`This will overwrite waypoints for ${currentWalk?.name}.`} saveStatus={savingWalkStatus} onConfirm={handleSaveWalk} onCancel={() => setShowConfirmationModal(false)} />

      <ConfirmationModal isOpen={showDeleteConfirmationModal} title={`Delete ${currentWalk?.name}?`} message={`This will delete ${currentWalk?.name} and it's waypoints.`} saveStatus={deleteStatus} onConfirm={handleDeleteWalk} onCancel={() => setShowDeleteConfirmationModal(false)} />
      <NewWalkModal
        isOpen={showNewWalkModal}
        title={`${currentLocation.name}`}
        message={"Give this walk a name and brief description."}
        onConfirm={handleSaveWalk}
        onCancel={() => setShowNewWalkModal(false)}
        handleWalkName={handleWalkName}
        handleWalkDescription={handleWalkDescription}
        walkName={walkName}
        walkDescription={walkDescription}
        status={savingWalkStatus}
      />{" "}
    </div>
  )
}
