// src/hooks/useDeviceView.ts
import { useEffect, useState } from 'react';

export const useDeviceView = () => {
  const [deviceView, setDeviceView] = useState(0);

  useEffect(() => {
    const updateDeviceView = () => {
      // Logic to determine device view size
      // Example: setDeviceView(window.innerWidth);
    };

    window.addEventListener('resize', updateDeviceView);
    updateDeviceView(); // Initial call

    return () => window.removeEventListener('resize', updateDeviceView);
  }, []);

  return deviceView;
};