import React, { useState, useContext, useEffect } from "react"
import { Incident } from "../../Interfaces"
import Switch from "@mui/material/Switch"
import PopupModal from "./PopupModal"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import { incidentService } from "../../services/IncidentService"
import { CircularProgress } from "@mui/material"
import DataContext from "../../context/dataProvider"

interface IncidentModalProps {
  incident: Incident | null
  isOpen: boolean
  onClose: () => void
  onUpdate?: (updatedIncident: Incident) => void
}

export default function IncidentModal({ incident, isOpen, onClose, onUpdate }: IncidentModalProps) {
  const { currentUser } = useContext(DataContext)
  const [newNote, setNewNote] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [currentIncident, setCurrentIncident] = useState<Incident | null>(null)
  const [imageLoadingStates, setImageLoadingStates] = useState<boolean[]>([])
  const [isResolved, setIsResolved] = useState<boolean>(false)
  const [showOldestFirst, setShowOldestFirst] = useState(false)

  useEffect(() => {
    if (incident && isOpen) {
      fetchIncident();
    }
  }, [incident, isOpen]);

  const fetchIncident = async () => {
    if (!incident) return;
    try {
      const freshIncident = await incidentService.getOne(incident.id);
      setCurrentIncident(freshIncident);
      setIsResolved(!!freshIncident.resolvedAt);
      setImageLoadingStates(new Array(freshIncident.images.length).fill(true));
    } catch (error) {
      console.error("Error fetching incident:", error);
    }
  };

  const handleAddNote = async () => {
    if (!currentIncident || !newNote.trim() || !currentUser) return;

    setIsLoading(true);
    try {
      await incidentService.addNote({ incidentId: currentIncident.id, note: newNote, userId: currentUser.id });
      const updatedIncident = await incidentService.getOne(currentIncident.id);
      if (onUpdate) {
        onUpdate(updatedIncident);
      }
      setNewNote("");
      onClose();
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleToggleResolved = async () => {
    if (!currentIncident) return;

    try {
      const updatedIncident = await incidentService.resolve({
        incidentId: currentIncident.id,
        resolved: !isResolved,
        resolvedAt: !isResolved ? new Date().toISOString() : null
      });

      setIsResolved(!isResolved);
      if (onUpdate) {
        onUpdate(updatedIncident);
      }
    } catch (error) {
      console.error("Error updating resolved status:", error);
    }
  };

  const handleImageLoad = (index: number) => {
    setImageLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const sortedNotes = currentIncident?.notes ? [...currentIncident.notes].sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return showOldestFirst ? dateA - dateB : dateB - dateA;
  }) : [];

  if (!currentIncident) return null;

  return (
    <PopupModal isOpen={isOpen} onCancel={onClose} className="w-3/4 max-w-6xl h-[90vh]">
      <div className="flex h-[calc(90vh-2rem)] p-4">
        <div className="w-3/5 pr-4 flex flex-col">
          <h2 className="text-xl font-bold mb-4">Incident {currentIncident.id}</h2>
          <div className="w-full flex justify-between items-center mb-4">
            <div>
              <Switch
                checked={isResolved}
                onChange={handleToggleResolved}
                color="primary"
                name="resolvedSwitch"
                inputProps={{ 'aria-label': 'Resolve Incident' }}
              />
              <span>Resolved</span>
            </div>
            <div className="flex items-center">
              <span className={`text-sm mr-2 ${!showOldestFirst ? 'font-bold' : ''}`}>Newest</span>
              <Switch
                checked={showOldestFirst}
                onChange={(e) => setShowOldestFirst(e.target.checked)}
                color="primary"
                name="orderSwitch"
                inputProps={{ 'aria-label': 'Note Order' }}
              />
              <span className={`text-sm ml-2 ${showOldestFirst ? 'font-bold' : ''}`}>Oldest</span>
            </div>
          </div>
          <div className="mb-4">
            <TextareaAutosize
              minRows={2}
              placeholder="Add a new note..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <button
              onClick={handleAddNote}
              disabled={isLoading}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {isLoading ? "Adding..." : "Add Note"}
            </button>
          </div>
          <div className="overflow-y-auto h-[calc(90vh-25rem)]">
            {sortedNotes.map((note, index) => (
              <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
                <p>{note.note}</p>
                <p className="text-xs text-gray-500 flex items-center gap-2">
                  <span className="font-semibold">{note.user?.firstName} {note.user?.lastName}</span>
                  <span>-</span>
                  <span>{new Date(note.createdAt).toLocaleString()}</span>
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="w-2/5 pl-4 flex flex-col">
          <h3 className="text-lg font-semibold mb-4">Images</h3>
          <div className="overflow-y-scroll scrollbar-hide h-[calc(90vh-10rem)]">
            <div className="flex flex-col items-center">
              {currentIncident?.images?.map((image, index) => (
                <div key={index} className="mb-4 w-full">
                  {imageLoadingStates[index] && (
                    <div className="w-full h-64 flex items-center justify-center bg-gray-200">
                      <CircularProgress />
                    </div>
                  )}
                  <img
                    src={image.imageUrl}
                    alt={`Incident ${currentIncident.id} - Image ${index + 1}`}
                    className={`w-full h-auto object-contain ${imageLoadingStates[index] ? 'hidden' : ''}`}
                    onLoad={() => handleImageLoad(index)}
                    onError={() => handleImageLoad(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PopupModal>
  );
} 