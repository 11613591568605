import React, { useState, useCallback, useMemo, useEffect } from "react"
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api"
import { Incident } from "../../Interfaces"
import Switch from "@mui/material/Switch"
import IncidentModal from "../modals/IncidentModal"
import { GOOGLE_MAPS_CONFIG } from '../../config/googleMapsConfig'

interface IncidentsViewProps {
  locationId: number
  incidents: Incident[]
  showUnresolvedOnly: boolean
  setShowUnresolvedOnly: (value: boolean) => void
  coordinates: { lat: number; lng: number }
  zoom: number
}

const IncidentsView: React.FC<IncidentsViewProps> = ({
  locationId,
  incidents,
  showUnresolvedOnly,
  setShowUnresolvedOnly,
  coordinates,
  zoom
}) => {
  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hoveredIncidentId, setHoveredIncidentId] = useState<number | null>(null)
  const [mapKey, setMapKey] = useState(0)

  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG)

  const handleIncidentClick = (incident: Incident) => {
    setSelectedIncident(incident)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedIncident(null)
  }

  const handleIncidentUpdate = (updatedIncident: Incident) => {
    // Refresh the incidents list after an update
    // You might want to call a prop function here to refresh the parent's incident list
  }

  const mapRef = useCallback((node: google.maps.Map | null) => {
    if (node !== null) {
      // You can perform any necessary operations on the map instance here
    }
  }, [])

  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
  }, [hoveredIncidentId]);

  const markers = useMemo(() => {
    if (!isLoaded) return [];
    return incidents.map((incident) => (
      <MarkerF
        key={incident.id}
        position={{ lat: incident.latitude, lng: incident.longitude }}
        onClick={() => handleIncidentClick(incident)}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: hoveredIncidentId === incident.id ? 12 : 6,
          fillColor: incident.id === hoveredIncidentId ? '#FF0000' : '#0000FF',
          fillOpacity: 0.8,
          strokeWeight: 2,
          strokeColor: incident.id === hoveredIncidentId ? '#FF0000' : '#0000FF',
        }}
      />
    ));
  }, [incidents, hoveredIncidentId, handleIncidentClick, isLoaded]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="h-1/2">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={coordinates}
          zoom={zoom}
          onLoad={mapRef}
          key={mapKey}
          options={{
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.SATELLITE
          }}
        >
          {markers}
        </GoogleMap>
      </div>
      <div className="h-1/2 flex flex-col">
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-bold">Incidents</h2>
          <div className="flex items-center">
            <span className="mr-2">Show Unresolved Only</span>
            <Switch
              checked={showUnresolvedOnly}
              onChange={(e) => setShowUnresolvedOnly(e.target.checked)}
              color="primary"
            />
          </div>
        </div>
        <div className="flex-1 min-h-0 overflow-y-scroll scrollbar-hide">
          <ul className="divide-y divide-gray-200">
            {incidents.map((incident) => (
              <li
                key={incident.id}
                className="p-4 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleIncidentClick(incident)}
              >
                <div className="flex justify-between items-center p-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold">{incident.notes[0]?.note}</h3>
                  </div>
                  <div className="flex-1 text-sm text-gray-600">
                    <p>{new Date(incident.createdAt).toLocaleDateString()}</p>
                  </div>
                  <div className="flex-1 mt-1">
                    <p>
                      {incident.resolvedAt
                        ? `Resolved at: ${new Date(incident.resolvedAt).toLocaleDateString()}`
                        : "Unresolved"}
                    </p>
                  </div>
                  <div className="flex-1 text-sm text-gray-600">
                    <p>Images: {incident.images.length}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <IncidentModal
        incident={selectedIncident}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUpdate={handleIncidentUpdate}
      />
    </div>
  );
}

export default IncidentsView
