import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { incidentService } from '../../services/IncidentService'
import Table from './common/Table'
import { useDeviceView } from '../../hooks/useDeviceView'
import { TABLET, LARGE_TABLET, LARGE_DESKTOP } from '../../utils/screenSize'
import { Incident } from '../../Interfaces'
import Switch from "@mui/material/Switch"
import IncidentModal from '../modals/IncidentModal'

const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

export default function IncidentsTable() {
  const [incidents, setIncidents] = useState<Incident[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [showUnresolvedOnly, setShowUnresolvedOnly] = useState(true)
  const deviceView = useDeviceView()
  const navigate = useNavigate()
  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    fetchIncidents()
  }, [searchTerm, showUnresolvedOnly])

  const fetchIncidents = async () => {
    try {
      setIsLoading(true)
      const data = await incidentService.getAll()
      const filteredData = showUnresolvedOnly 
        ? data.filter((incident: Incident) => !incident.resolvedAt)
        : data
      setIncidents(filteredData)
    } catch (error) {
      console.error("Error fetching incidents:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const headers = [
    { label: "Created", key: "createdAt" },
    { label: "Location", key: "locationName" },
    { label: "First Note", key: "oldestNote" },
    { label: "Latest Note", key: "latestNote", hide: true },
    { label: "Status", key: "resolvedAt" },
    { label: "Images", key: "imagesCount" }
  ]

  if (deviceView >= TABLET) {
    headers.unshift({ label: "ID", key: "id" })
  }

  if (deviceView >= LARGE_TABLET) {
    headers.push({ label: "Notes", key: "notesCount" })
  }

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const handleIncidentClick = (incident: Incident) => {
    setSelectedIncident(incident)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedIncident(null)
  }

  const handleIncidentUpdate = (updatedIncident: Incident) => {
    fetchIncidents();
    
    setIsModalOpen(false);
    setSelectedIncident(null);
  }

  if (isLoading) return null

  return (
    <>
      <Table 
        data={incidents.map(incident => {
          const sortedNotes = [...incident.notes].sort((a, b) => 
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
          
          const hasMultipleNotes = sortedNotes.length > 1;
          
          return {
            ...incident,
            createdAt: new Date(incident.createdAt).toLocaleDateString(),
            locationName: incident.walk?.location?.name || 'Unknown Location',
            resolvedAt: incident.resolvedAt ? 'Resolved' : 'Unresolved',
            notesCount: incident.notes?.length || 0,
            imagesCount: incident.images?.length || 0,
            oldestNote: truncateText(sortedNotes[0]?.note || '', 25),
            latestNote: hasMultipleNotes ? truncateText(sortedNotes[sortedNotes.length - 1]?.note || '', 25) : ''
          };
        })}
        title="Incidents"
        handleSearch={handleSearch}
        headers={headers}
        extraHeaderContent={
          <div className="flex items-center">
            <span className="text-gray-600 mr-2">Show Unresolved Only</span>
            <Switch
              checked={showUnresolvedOnly}
              onChange={(e) => setShowUnresolvedOnly(e.target.checked)}
              color="primary"
            />
          </div>
        }
        onRowClick={handleIncidentClick}
      />
      <IncidentModal
        incident={selectedIncident}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onUpdate={handleIncidentUpdate}
      />
    </>
  )
} 